import { useCallback, useEffect, useState } from "react";
import { MessageList } from "react-chat-elements";
import "react-chat-elements/dist/main.css";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { useAuth } from "../AuthContext";
import { getChannels } from "../http/channelsAPI";

import { getMessages } from "../http/messagesAPI";
import { URL } from "../utils/consts";
import styles from "./styles/Chat.module.css";
import { scrollToBottom, formatMessage } from "../components/chat/utils";
import ChatsList from "../components/chat/ChatsList";
import UsernameBox from "../components/chat/UsernameBox";
import InputBox from "../components/chat/InputBox";
import PinnedFilesBox from "../components/chat/PinnedFilesBox";
import { sortedForChats } from "../utils/sortedForChats";
import { insertObjectBetween } from "../utils/insertObjectBetween";
import LoaderComponent from "../components/LoaderComponent";

const Chat = ({ socket, setIsPlay, setIsMessageSoundPlay }) => {
  const { chatId } = useAuth();
  const { state } = useLocation();

  const [messages, setMessages] = useState([]);
  const [channels, setChannels] = useState([]);
  const [channel, setChannel] = useState("");
  const [text, setText] = useState("");
  const [name, setName] = useState("");
  const [file, setFile] = useState([]);
  const [isChatslistLoad, setIsChatslistLoad] = useState(true);
  const [isUsernameBoxLoad, setIsUsernameBoxLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const [showMessages, setShowMessages] = useState(false);

  const [consultation, setConsultation] = useState(false);

  const [avatar, setAvatar] = useState(null);
  const [status, setStatus] = useState(null);

  const [showList, setShowList] = useState(true);

  function getAvatarUrlByParticipant(participants) {
    for (const participant of participants) {
      if (participant.isManager) continue;

      if (!participant.isManager && participant.avatarUrl) {
        return `${URL}${participant.avatarUrl}`;
      }

      return "/chatImg/1.jpg";
    }
  }

  useEffect(() => {
    const setChat = async () => {
      const ccc = await fetchChannels();
      setChannels(ccc);

      if (state && ccc) {
        setIsUsernameBoxLoad(true);
        setChannel(state.idChat);

        const foundItemIndex = ccc.findIndex(
          (item) => item.id === state.idChat
        );

        if (foundItemIndex === -1) {
          toast.info("Консультацію не знайдено");
        } else {
          const foundItem = ccc[foundItemIndex];
          setAvatar(getAvatarUrlByParticipant(foundItem.participants));
          setConsultation(foundItem.consultation);
          setName(foundItem.name);
          setStatus(foundItem.status);
          setIsUsernameBoxLoad(false);
          setText("");
          setCurrentPage(Math.floor(foundItemIndex / 10));
          setFile(null);
          const messagesForChannel = await getMessages(state.idChat);
          const formattedMessages = messagesForChannel.map((m) =>
            formatMessage(m, chatId)
          );
          setMessages(insertObjectBetween(formattedMessages));
          setTimeout(function () {
            scrollToBottom();
          }, 1);
        }
      }
    };
    setChat();
    // eslint-disable-next-line
  }, [state]);

  const fetchChannels = useCallback(async () => {
    try {
      const channelsData = await getChannels();

      const filteredArray = sortedForChats(channelsData);

      const chatIds = filteredArray.reduce((acc, channel) => {
        if (
          channel.status === "wait-manager" ||
          channel.status === "wait-user"
        ) {
          acc.push(channel.id);
        }
        return acc;
      }, []);

      localStorage.setItem("chatIds", JSON.stringify(chatIds));

      setChannels(filteredArray);
      return filteredArray;
    } catch (error) {
      toast.error("Щось пішло не так, оновіть сторінку");
    } finally {
      setIsChatslistLoad(false);
    }
  }, []);

  const handleNotificationClick = () => {
    setIsPlay(0);
  };

  useEffect(() => {
    socket.on("message", async (newMessage) => {
      const indexOfUpdatedChannel = channels.findIndex(
        (item) => item.id === newMessage.channelId
      );
      console.log(indexOfUpdatedChannel);
      if (indexOfUpdatedChannel !== -1) {
        const newArray = [...channels];
        newArray[indexOfUpdatedChannel] = {
          ...newArray[indexOfUpdatedChannel],
          youngestMessage: newMessage.createdAt,
          messages: [...newArray[indexOfUpdatedChannel].messages, newMessage],
        };
        const filteredArray = sortedForChats(newArray);
        setChannels(filteredArray);
      }
      const storedChatIds = JSON.parse(localStorage.getItem("chatIds")) || [];
      if (
        storedChatIds.includes(newMessage.channelId) &&
        newMessage.type === "text" &&
        chatId !== newMessage.user.id
      ) {
        const truncatedText =
          newMessage.text.length > 50
            ? `${newMessage.text.slice(0, 50)}...`
            : newMessage.text;
        toast.info(
          `Нове повідомлення\n${newMessage.user.firstName}:\n ${truncatedText}`
        );
        setIsMessageSoundPlay((prev) => (prev += 1));
      }
      if (newMessage.channelId === channel) {
        const formattedMessage = formatMessage(newMessage, chatId);

        setMessages((prevMessages) => [...prevMessages, formattedMessage]);
        setTimeout(function () {
          scrollToBottom();
        }, 1);
      }
    });

    socket.on("channel", (newChannel) => {
      setChannels((prev) => [...prev, newChannel]);
    });
    socket.on("newConsultation", () => {
      toast.info("Зявився новий запит", {
        onClick: handleNotificationClick,
        autoClose: 9000,
      });
      setIsPlay((prev) => (prev += 1));
    });
    socket.on("update-channel", async () => {
      await fetchChannels();
    });

    return () => {
      socket.off("message");
      socket.off("update-channel");
      socket.off("channel");
      socket.off("newConsultation");
    };
    // eslint-disable-next-line
  }, [socket, channel, channels]);

  const clear = () => {
    setAvatar(null);
    setChannel("");
    setFile([]);
    setMessages([]);
    setName("");
    setText("");
  };

  if (isChatslistLoad) {
    return <LoaderComponent />;
  }

  return (
    <section className={styles.container}>
      <ChatsList
        channels={channels}
        channel={channel}
        setChannel={setChannel}
        setStatus={setStatus}
        setAvatar={setAvatar}
        setName={setName}
        setMessages={setMessages}
        setFile={setFile}
        setText={setText}
        chatId={chatId}
        consultation={consultation}
        setConsultation={setConsultation}
        isChatslistLoad={isChatslistLoad}
        state={state}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setShowMessages={setShowMessages}
        showList={showList}
        setShowList={setShowList}
      ></ChatsList>
      <div
        className={`${showMessages ? styles.show_messages : ""} ${
          styles.messages
        }`}
      >
        <UsernameBox
          avatar={avatar}
          name={name}
          status={status}
          clear={clear}
          channel={channel}
          fetchChannels={fetchChannels}
          consultation={consultation}
          isUsernameBoxLoad={isUsernameBoxLoad}
          setShowList={setShowList}
          setShowMessages={setShowMessages}
        />

        <MessageList
          className={styles.messageList}
          toBottomHeight={"100%"}
          dataSource={messages}
        />
        {file && file.length > 0 && (
          <PinnedFilesBox file={file} setFile={setFile} />
        )}

        <InputBox
          chatId={chatId}
          channel={channel}
          status={status}
          setText={setText}
          text={text}
          setFile={setFile}
          file={file}
        />
      </div>
    </section>
  );
};

export default Chat;
